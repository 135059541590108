/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Typography, Link } from "@material-ui/core";

import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
      margin: "5rem 0rem 1.5rem 0rem",
    },
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "5rem 0rem 1rem 0rem",
  },
  heading: {
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: "1.875rem",
    textAlign: "center",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.125rem",
      lineHeight: "3.1875rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  headingText: {
    width: "100%",
    margin: "2rem 0rem 2rem 0rem",
  },
  cardStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "10rem",
    width: "10rem",
    gap: "4rem",
    [theme.breakpoints.down("xs")]: {
      height: "8rem",
      width: "8rem",
    },
    padding: "0.5rem",
  },
}));

const OnlineOrder = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <span id="ordernow"></span>
      <div className={classes.sectionContainer}>
        <div className={classes.headingText}>
          <Typography
            variant="body2"
            component="span"
            className={classes.heading}
          >
            We are available on Swiggy and Zomato
          </Typography>
        </div>
        <div className={classes.cardStyle}>
          <Link
            href="https://www.swiggy.com/restaurants/paradigm-shift-organica-hsr-hsr-layout-bangalore-360483"
            rel="noopener"
            target="_blank"
          >
            <img
              src="/images/others/swiggy_logo.webp"
              alt={AppConfig.imageAltText}
              width={120}
              height={120}
              loading="lazy"
              referrerPolicy="no-referrer"
              longdesc="https://psolifestyle.com"
              className={classes.illustrationSizer}
            />
          </Link>
          <Link
            href="https://www.zomato.com/bangalore/paradigm-shift-organica-hsr-bangalore"
            rel="noopener"
            target="_blank"
          >
            <img
              src="/images/others/zomato_logo.webp"
              alt={AppConfig.imageAltText}
              width={120}
              height={120}
              loading="lazy"
              referrerPolicy="no-referrer"
              longdesc="https://psolifestyle.com"
              className={classes.illustrationSizer}
            />
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OnlineOrder;
