/* Common Imports */

import React from "react";

/* Component Imports */

import Layout from "../components/layout-components/Layout";
import SectionOne from "../components/page-sections/home-page/SectionOne";
// import SectionTwo from "../components/page-sections/home-page/SectionTwo";
// import SectionThree from "../components/page-sections/home-page/SectionThree";
import SectionFour from "../components/page-sections/home-page/SectionFour";
import OurStory from "../components/page-sections/home-page/OurStory";
import TrendingDishes from "../components/page-sections/home-page/TrendingDishes";
import SectionSeven from "../components/page-sections/home-page/SectionSeven";
import OnlineOrder from "../components/page-sections/home-page/OnlineOrder";
import ContactUs from "../components/page-sections/home-page/ContactUs";

const Index = (props) => {
  return (
    <React.Fragment>
      <Layout>
        <SectionOne />
        {/* <SectionTwo /> */}
        {/* <SectionThree /> */}
        <SectionFour />
        <OurStory />
        <TrendingDishes />
        <SectionSeven />
        <OnlineOrder />
        <ContactUs />
      </Layout>
    </React.Fragment>
  );
};

export default Index;
