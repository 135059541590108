/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

/* Component Imports */
import { Typography } from "@material-ui/core";

/* Data Imports */
import AppConfig from "../../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    // boxShadow:
    //   "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
    // borderRadius: "0.375rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "30%",
    },
    [theme.breakpoints.only("md")]: {
      width: "30%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "30%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "30%",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      width: "30%",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 920)]: {
      width: "15%",
    },
  },
  media: {
    margin: "0.5rem 0rem 0rem 0rem",
    backgroundSize: "auto",
  },
  infotitle: {
    fontWeight: "600",
    fontSize: "1.25rem",
    lineHeight: "1.875rem",
    marginTop: "0.5em",
  },
  infotext: {
    fontWeight: "400",
    lineHeight: "1.25rem",
    color: "rgba(158, 158, 158, 1)",
    marginTop: "1rem",
    [theme.breakpoints.up("lg")]: {
      fontSize: "1rem",
    },
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const AboutCard = (props) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.root,
        props.cardClass ? props.cardClass : classes.cardClass
      )}
      style={{
        height: props.height ? props.height : "15rem",
        width: props.width ? props.width + " !important" : undefined,
      }}
    >
      <div className={classes.media}>
        <img
          decode="async"
          alt={AppConfig.imageAltText}
          longdesc="https://psolifestyle.com"
          loading="lazy"
          referrerPolicy="no-referrer"
          height={70}
          width={70}
          src={props.icon ? props.icon : "/images/icons/social/location.svg"}
        />
      </div>
      <div className={classes.content}>
        <Typography
          variant="h6"
          component="h6"
          align="center"
          className={classes.infotitle}
        >
          {props.title}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          align="center"
          className={classes.infotext}
        >
          {props.text}
        </Typography>
      </div>
    </div>
  );
};

export default AboutCard;
