/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Typography } from "@material-ui/core";

/* Data Imports */

import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
      margin: "5rem 0rem 1.5rem 0rem",
    },
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "5rem 0rem 1rem 0rem",
    gap: "2rem",
  },
  text: {
    [theme.breakpoints.between("sm", "md")]: {
      marginLeft: "2rem",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  heading: {
    width: "100%",
    color: "#000000",
    fontWeight: "500",
    fontSize: "2.125rem",
    lineHeight: "3.1875rem",
    margin: "0rem 0rem 0.5rem 0rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  subHeading: {
    margin: "0.5rem 0rem 0.5rem 0rem",
    width: "100%",
    color: "#666666",
    fontWeight: "400",
    fontSize: "1.125rem",
    lineHeight: "1.75rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      letterSpacing: "0.016rem",
    },
  },
  illustrationSizer: {
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      height: "280px",
      width: "280px",
    },
    [theme.breakpoints.only("xs")]: {
      height: "320px",
      width: "320px",
    },
    [theme.breakpoints.only("sm")]: {
      height: "352px",
      width: "352px",
    },
    [theme.breakpoints.only("md")]: {
      height: "350px",
      width: "350px",
    },
    [theme.breakpoints.only("lg")]: {
      height: "480px",
      width: "480px",
    },
    [theme.breakpoints.only("xl")]: {
      height: "511px",
      width: "536px",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      height: "768px",
      width: "768px",
    },
  },
}));

const SectionFour = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <span id="about"></span>
      <div className={classes.sectionContainer}>
        <div className={classes.animation}>
          <img
            src="/images/dishes/story1.png"
            alt={AppConfig.imageAltText}
            width={512}
            height={512}
            loading="eager"
            referrerPolicy="no-referrer"
            longdesc="https://psolifestyle.com"
            className={classes.illustrationSizer}
          />
        </div>
        <div className={classes.text}>
          <Typography variant="h1" component="span" className={classes.heading}>
            Our story
          </Typography>
          <Typography
            variant="body2"
            component="span"
            className={classes.subHeading}
          >
            When paradigms change, the world itself changes with them. So
            embrace a healthy lifestyle with our assortment of plant-based,
            organic and tasty food. Since a decade ago, our founders have been
            working on this project to give back to the environment and society,
            foster communities and help farmers regenerate our soil and evolve
            the future of food. The genesis of PSO was the outcome of this
            exceptional philosophy of plant-based food and a healthy lifestyle.
            We pride ourselves on offering the highest quality organic food at
            affordable prices. Our meals and snacks are freshly made from the
            finest organic ingredients sourced from certified organic farms and
            stores.
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SectionFour;
