/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Typography } from "@material-ui/core";
import AboutCard from "./sub-components/AboutCard";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  heading: {
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: "3.1875rem",
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.125rem",
      lineHeight: "3.1875rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  headingText: {
    width: "100%",
    margin: "2rem 0rem 2rem 0rem",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "1rem",
    },
    width: "100%",
    margin: "1rem 0rem 1rem 0rem",
  },
}));

const SectionFour = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.sectionContainer}>
        <div className={classes.headingText}>
          <Typography
            variant="body2"
            component="span"
            className={classes.heading}
          >
            Why choose us
          </Typography>
        </div>
        <div className={classes.cardContainer}>
          <AboutCard
            icon="/images/others/aboutus/plantbased.svg"
            title="Plant Based"
            text="We offer plant-based food to ensure a healthy approach to your lifestyle."
          />
          <AboutCard
            icon="/images/others/aboutus/organic.svg"
            title="Organic"
            text="Organic food to make sure you get a nutrient-rich and healthier diet."
          />
          <AboutCard
            icon="/images/others/aboutus/super_tasty.svg"
            title="Super Tasty"
            text="Flavourful food that is appetizing and super tasty!"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SectionFour;
