/* Common Imports */

import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

/* Component Imports */

import { Typography, Button, useMediaQuery } from "@material-ui/core";

/* Data Imports */

import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
      margin: "5rem 0rem 1.5rem 0rem",
    },
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "1rem 0rem 1rem 0rem",
  },
  text: {
    [theme.breakpoints.between("sm", "md")]: {
      marginLeft: "2rem",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  heading: {
    width: "100%",
    color: "#000000",
    fontWeight: "400",
    fontSize: "2.125rem",
    lineHeight: "3.1875rem",
    margin: "0rem 0rem 0.5rem 0rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  boldRed: {
    color: "#CC0000",
    fontWeight: "bold",
    background: "-webkit-linear-gradient(0deg, #F74134, #EB1C61)",
    "-webkit-background-clip": "text",
    "-webkit-text-fillColor": "transparent",
  },
  subHeading: {
    margin: "0.5rem 0rem 0.5rem 0rem",
    width: "100%",
    color: "#666666",
    fontWeight: "400",
    fontSize: "1.125rem",
    lineHeight: "1.75rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      letterSpacing: "0.016rem",
    },
    [theme.breakpoints.only("lg")]: {
      display: "none",
    },
  },
  subHeadinglg: {
    [theme.breakpoints.only("lg")]: {
      margin: "0.5rem 0rem 0.5rem 0rem",
      width: "100%",
      color: "#666666",
      fontWeight: "400",
      fontSize: "1.125rem",
      lineHeight: "1.75rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    margin: "0.5rem 0rem 0rem 0rem",
  },
  primaryButton: {
    color: "#FFFFFF",
    background: "-webkit-linear-gradient(0deg, #75E88A, #2CB646)",
  },
  illustrationSizer: {
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      height: "280px",
      width: "280px",
    },
    [theme.breakpoints.only("xs")]: {
      height: "320px",
      width: "320px",
    },
    [theme.breakpoints.only("sm")]: {
      height: "352px",
      width: "352px",
    },
    [theme.breakpoints.only("md")]: {
      height: "352px",
      width: "352px",
    },
    [theme.breakpoints.only("lg")]: {
      height: "400px",
      width: "400px",
    },
    [theme.breakpoints.only("xl")]: {
      height: "400px",
      width: "400px",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      height: "768px",
      width: "768px",
    },
  },
}));

const SectionSeven = () => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.sectionContainer}>
        <div className={classes.animation}>
          <img
            src="/images/dishes/cake.png"
            alt={AppConfig.imageAltText}
            width={480}
            height={480}
            loading="eager"
            referrerPolicy="no-referrer"
            longdesc="https://advisory.beegru.com"
            className={classes.illustrationSizer}
          />
        </div>
        <div className={classes.text}>
          <Typography variant="h1" component="span" className={classes.heading}>
            Extend your life with an organic slice!
          </Typography>
          <Typography
            variant="body2"
            component="span"
            className={classes.subHeading}
          >
            Some things in life require a cake, but what more can you ask when
            it is
            {useMediaQuery(theme.breakpoints.up("lg")) ? (
              <br />
            ) : undefined}{" "}
            infused with organic, healthy and plant-based ingredients? So let us
            cherish{" "}
            {useMediaQuery(theme.breakpoints.up("lg")) ? <br /> : undefined}
            all your special celebrations with a range of exotic cakes that
            would turn any{" "}
            {useMediaQuery(theme.breakpoints.up("lg")) ? <br /> : undefined}
            event into an awesome one.
          </Typography>
          <Typography
            variant="body2"
            component="span"
            className={classes.subHeadinglg}
          >
            Some things in life require a cake, but what more can you ask when
            it is infused with
            {useMediaQuery(theme.breakpoints.only("lg")) ? (
              <br />
            ) : undefined}{" "}
            organic, healthy and plant-based ingredients? So let us cherish all
            your special{" "}
            {useMediaQuery(theme.breakpoints.only("lg")) ? <br /> : undefined}
            celebrations with a range of exotic cakes that would turn any event
            into an awesome one.
          </Typography>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              href="#contact"
              title="Order Now"
              rel="noopener"
              className={classes.primaryButton}
            >
              Enquire Now
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SectionSeven;

