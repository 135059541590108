/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Button, Typography } from "@material-ui/core";
import BasicCard from "./sub-components/BasicCard";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  heading: {
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: "3.1875rem",
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.125rem",
      lineHeight: "3.1875rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  headingText: {
    width: "100%",
    margin: "2rem 0rem 2rem 0rem",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "noWrap",
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "-webkitOverflowScrolling": "touch",
      gap: "1.5rem",
    },
    width: "100%",
    margin: "1rem 0rem 1rem 0rem",
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    margin: "2rem 0rem 0rem 0rem",
  },
  primaryButton: {
    color: "#FFFFFF",
    background: "-webkit-linear-gradient(0deg, #75E88A, #2CB646)",
  },
}));

const TrendingDishes = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.sectionContainer}>
        <div className={classes.headingText}>
          <Typography
            variant="body2"
            component="span"
            className={classes.heading}
          >
            Our Trending Dishes
          </Typography>
        </div>
        <div className={classes.cardContainer}>
          <BasicCard
            imageLink="/images/dishes/oats_trending.webp"
            text="Oats Cookies"
          />
          <BasicCard
            imageLink="/images/dishes/brownie_trending.webp"
            text="Choco Brownie"
          />
          <BasicCard
            imageLink="/images/dishes/cookie_trending.webp"
            text="Peanut Cookies"
          />
          <BasicCard
            imageLink="/images/dishes/bread_trending.webp"
            text="Millet Bread"
          />
        </div>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            href="/menu"
            target="_blank"
            title="View Menu"
            rel="noopener"
            className={classes.primaryButton}
          >
            View Menu
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TrendingDishes;
