import React from "react";

import { Typography, makeStyles, Button } from "@material-ui/core";

import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
    justifyContent: "space-between",
    alignItems: "center",
    height: "calc(100vh - 4rem)",
    width: "100%",
    margin: "0rem 0rem 1rem 0rem",
  },
  text: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
  },
  heading: {
    width: "100%",
    color: "#000000",
    fontWeight: "400",
    fontSize: "3.75rem",
    lineHeight: "5.625rem",
    margin: "0rem 0rem 0.5rem 0rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.125rem",
      lineHeight: "3.1875rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    margin: "0.5rem 0rem 0rem 0rem",
  },
  primaryButton: {
    color: "#FFFFFF",
    background: "-webkit-linear-gradient(0deg, #75E88A, #2CB646)",
  },
  illustrationSizer: {
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      height: "280px",
      width: "280px",
      margin: "3rem 0rem 0rem 0rem",
    },
    [theme.breakpoints.only("xs")]: {
      height: "320px",
      width: "320px",
      margin: "3rem 0rem 0rem 0rem",
    },
    [theme.breakpoints.only("sm")]: {
      height: "352px",
      width: "352px",
    },
    [theme.breakpoints.only("md")]: {
      height: "384px",
      width: "384px",
    },
    [theme.breakpoints.only("lg")]: {
      height: "512px",
      width: "512px",
    },
    [theme.breakpoints.only("xl")]: {
      height: "512px",
      width: "512px",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      height: "768px",
      width: "768px",
    },
  },
}));

const SectionOne = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.sectionContainer}>
        <div className={classes.text}>
          <Typography variant="h2" component="h2" className={classes.heading}>
            Give yourself the advantage of good health every day!
          </Typography>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              href="#ordernow"
              title="Order Now"
              rel="noopener"
              className={classes.primaryButton}
            >
              Order Now
            </Button>
          </div>
        </div>
        <div>
          <img
            src="/images/dishes/home.webp"
            alt={AppConfig.imageAltText}
            width={512}
            height={512}
            loading="eager"
            referrerPolicy="no-referrer"
            longdesc="https://psolifestyle.com"
            className={classes.illustrationSizer}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SectionOne;
