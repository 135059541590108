/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Typography } from "@material-ui/core";

/* Data Imports */

import AppConfig from "../../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  cardStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "10rem",
    width: "10rem",
  },
  text: {
    color: "#202020",
    fontWeight: 500,
    fontSize: "1.125rem",
    lineHeight: "1.75rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.125rem",
      lineHeight: "1.6875rem",
    },
    textAlign: "center",
    width: "100%",
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  illustrationSizer: {
    [theme.breakpoints.down("xs")]: {
      height: "120px",
      width: "120px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "138px",
      width: "150px",
    },
  },
}));

const BasicCard = (props) => {
  const classes = useStyles();

  /* LQIP state management */

  const [src, setSrc] = React.useState(
    props.imageLink.substring(0, props.imageLink.lastIndexOf(".")) + ".webp"
  );

  React.useEffect(() => {
    setSrc(props.imageLink);
  }, []);

  return (
    <React.Fragment>
      {/* <Link
        href={props.href ? props.href : "#keynote"}
        title={props.title ? props.title : "Residential Properties"}
        aria-label={props.title ? props.title : "Residential Properties"}
        rel="noopener"
        className={classes.link}
      > */}
      <div className={classes.cardStyle}>
        <img
          src={props.imageLink ? src : "/images/dishes/soup.png"}
          alt={AppConfig.imageAltText}
          width={150}
          height={137}
          loading="lazy"
          referrerPolicy="no-referrer"
          longdesc="https://psolifestyle.com"
          className={classes.illustrationSizer}
        />
        <Typography variant="body2" component="span" className={classes.text}>
          {props.text ? props.text : "Tom kha soup"}
        </Typography>
      </div>
      {/* </Link> */}
    </React.Fragment>
  );
};

export default BasicCard;
